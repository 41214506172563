<div class="temporarily-section p-4 d-flex align-items-center">
  <div class="container px-sm-5">
    <div class="row">
      <div class="col-lg-8 col-md-9 col-12">
        <img class="img-fluid" src="assets/img/cerra.svg" alt="" />
        <div class="h-title mt-5">
          {{ "temporarilyPage.temporarilyUnavailable" | translate }}
        </div>
        <div class="p-text mt-3">
          {{ "temporarilyPage.msg" | translate }}
        </div>
        <div class="btn-div">{{ date | date: "d MMM y" }}</div>
        <div class="p-text">
          {{ "temporarilyPage.thankyouText" | translate }}
        </div>
      </div>
      <div
        class="col-lg-4 col-md-3 col-12 d-md-flex align-items-center mx-auto mx-md-0 mt-5 mt-md-0"
      >
        <img
          class="img-fluid"
          src="../../../assets/img/cart-empty.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</div>
