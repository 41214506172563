import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-points-info',
  templateUrl: './points-info.component.html',
  styleUrl: './points-info.component.scss',
})
export class PointsInfoComponent {
  constructor(public dialogRef: MatDialogRef<PointsInfoComponent>) {}
}
