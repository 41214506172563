<div class="point-calculation p-3">
  <div class="text-center">
    <img class="mb-3" src="/assets/img/point-calc.png" alt="" />
    <div class="heading">
      {{ "msg.pointsInfo.howMuch" | translate }} <br />
      {{ "msg.pointsInfo.yaraPoints" | translate }}?
    </div>
    <div class="font-14">
      {{ "msg.pointsInfo.pointsCalculation" | translate }}
    </div>
    <hr class="my-5"/>
    <div class="point-card p-4">
      <b>{{ "msg.pointsInfo.earnYaraPoints" | translate }}</b>
      <div class="mt-3">
        {{ "msg.pointsInfo.earnText" | translate }}
      </div>
    </div>
  </div>
  <button class="btn btn-primary w-100 mt-5" (click)="dialogRef.close()">
    {{ "common.ok" | translate }}
  </button>
</div>
