import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'temporarily-unavailable',
  templateUrl: environment.components.temporarilyUnavailable.templateUrl,
  styleUrls: environment.components.temporarilyUnavailable.styleUrls
})
export class TemporarilyUnavailableComponent {
  date:Date = new Date();

}
