<input
  #uploader
  id="uploader"
  hidden
  type="file"
  accept="image/x-png,image/gif,image/jpeg"
  (change)="fileChangeEvent($event)"
  (cancel)="onKeydownHandler()"
/>
<div [ngClass]="data?.dialogMode ? 'box--top' : ''">
  <div
    class="row g-0 box--content"
    [ngClass]="data?.dialogMode ? 'dialog-mode' : ''"
  >
    <div class="txt--title">
      {{ "common.addImage" | translate }}
    </div>
    <div class="col-auto d-flex flex-column gap-2">
      <image-cropper
        *ngIf="!!uploadedFiles.length"
        class="box--img"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [canvasRotation]="canvasRotation"
        [transform]="transform"
        [aspectRatio]="4 / 3"
        [resizeToWidth]="128"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <a title="Flip Horizontal" (click)="flipHorizontal()">
            <img src="assets/img/icon-filp.svg" />
          </a>
          <a title="{{ 'common.leftRotate' | translate }}" (click)="rotateLeft()">
            <img src="assets/img/icon-rotate-left.svg" />
          </a>
          <a title="{{ 'common.rightRotate' | translate }}" (click)="rotateRight()">
            <img src="assets/img/icon-rotate-right.svg" />
          </a>
        </div>
        <div class="d-flex btn--action">
          <button
            type="button"
            class="btn btn-sm btn--img-action"
            (click)="saveCroppedImage()"
          >
            {{ "common.crop" | translate }}
          </button>
        </div>
      </div>
      <div *ngIf="data?.only1Image && data?.addCaption">
        <input class="form-control" type="text" [(ngModel)]="imageTitle" [placeholder]="'common.enterCaption' | translate" />
      </div>
    </div>
    <div class="col-auto d-flex flex-column box--previews">
      <div class="error--msg">{{message}}</div>
      <div *ngFor="let item of uploadedFiles">
        <img
          class="box--preview"
          [src]="item.imgBase64"
          (click)="cropImage(item)"
        />
      </div>
      <button
        *ngIf="
          !data?.only1Image || (data?.only1Image && uploadedFiles.length < 1)
        "
        class="box--add"
        (click)="uploader.click()"
      >
        +
      </button>
    </div>
  </div>
  <div *ngIf="data?.dialogMode" class="box--action">
    <div class="cancel--btn" [mat-dialog-close]>
      {{ "common.back" | translate }}
    </div>
    <div
      *ngIf="!!uploadedFiles.length"
      class="btn--post"
      [ngClass]="disabledClass"
      (click)="proceed()"
    >
      {{ "common.proceed" | translate }}
    </div>
    <div *ngIf="!uploadedFiles.length" class="btn--post disabled">
      {{ "common.proceed" | translate }}
    </div>
  </div>
  <div *ngIf="!data?.dialogMode" class="box--action">
    <div class="cancel--btn" (click)="goBack.emit()">
      {{ "common.back" | translate }}
    </div>
    <div class="btn--post" [ngClass]="disabledClass" (click)="proceed()">
      {{ "common.proceed" | translate }}
    </div>
  </div>
</div>
