import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api-services/api.service';
import { showError } from 'src/app/utility/common-logic';
import { AuthData } from 'src/app/utility/model/token-auth';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-consent-box',
  templateUrl: './consent-box.component.html',
  styleUrls: ['./consent-box.component.scss'],
})
export class ConsentBoxComponent implements OnInit {
  loading: boolean = false;
  authData: Partial<AuthData> = {};
  isFullContent = environment.settings?.afterLoginConsentBox?.isFullContent;
  content = environment.settings?.afterLoginConsentBox?.content;

  constructor(
    private api: ApiService,
    private dialogRef: MatDialogRef<ConsentBoxComponent>,
    private translate: TranslateService,
    private toastr: ToastrService,
    private authDataStore: Store<{ authData: AuthData }>,
  ) {}

  ngOnInit(): void {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => (this.authData = res));
  }

  closeModal() {
    localStorage.setItem('has_accepted_terms_and_cond', String(true));
    this.dialogRef.close({});
  }
  async onAgree() {
    const body = {
      has_accepted_terms_and_cond: true,
    };
    const userId = this.authData?.tokenAuth?.user_details?.pk;
    this.loading = true;
    await this.api
      .editUser(userId, body)
      .then((res) => {
        this.closeModal();
      })
      .catch((err) => showError(err, this.toastr, this.translate))
      .finally(() => (this.loading = false));
  }
}
