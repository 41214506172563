import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
  isDevMode,
} from '@angular/core';
import {
  DecimalPipe,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSidenavModule } from '@angular/material/sidenav';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { AppCommonModule } from './app-common/app-common.module';
import { httpInterceptorProviders } from './authentication/interceptors/httpInterceptorProviders';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgOtpInputModule } from 'ng-otp-input';
import { authDataReducer } from './state/auth-data/auth-data.reducer.';
import { appreciateReducer } from './state/appreciate/appreciate.reducer';
import {
  usersReducer,
  userCollectionReducer,
} from './state/user/users.reducer';

import { AppComponent } from './app.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TemporarilyUnavailableModule } from './temporarily-unavailable/temporarily-unavailable.module';


export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const serverErrors = new RegExp(
  `500 Internal Server Error|502 Bad Gateway|503 Service Unavailable|504 Gateway Timeout|401 Unauthorized`,
  'mi'
)

if (environment.production) {
  Sentry.init({
    dsn: 'https://4b96fe943552483a8b8a2d90c452a540@o1067282.ingest.sentry.io/4505160529018880',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay({
        networkDetailAllowUrls: [environment.url.sentryReplay],
        networkRequestHeaders: ['X-Custom-Header'],
        networkResponseHeaders: ['X-Custom-Header'],
      }),
    ],
    // Temporarily ignoring "Non-Error exception captured" for now
    beforeSend(event, hint) {
      const isNonErrorException =
        event.exception.values[0].value.startsWith('Non-Error exception captured') ||
        hint.originalException['message'].startsWith('Non-Error exception captured');

      if (isNonErrorException) {
        return null;
      }
      return event;
    },

    // Reading the environment from white-labeled settings
    environment: environment.settings.appName,
    // Performance Monitoring
    tracesSampleRate: 0.01, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: [serverErrors], // Ignoring servers errors logging from PWA since this is already logged from API end
  });
}

@NgModule({
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    AppCommonModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,
    MatSidenavModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgOtpInputModule,
    StoreModule.forRoot({
      authData: authDataReducer,
      appreciate: appreciateReducer,
      users: usersReducer,
      userCollection: userCollectionReducer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: false, // If set to true, the connection is established inside the Angular zone for better performance
    }),
    NgMultiSelectDropDownModule.forRoot(),
    TemporarilyUnavailableModule
  ],
  declarations: [AppComponent],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    DecimalPipe,
    DatePipe,
    httpInterceptorProviders,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
