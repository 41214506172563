import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { SharedService } from './services/shared.service';
import { PushNotifyService } from './services/push-notify.service';
import { LangService } from './services/lang.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AuthData } from 'src/app/utility/model/token-auth';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  app = environment.settings.app;
  applyBgColorFromDjango = environment.settings.applyBgColorFromDjango;
  isPushNotificationSupport = environment.settings.isPushNotificationSupport;
  freshchat = environment.settings.freshchat;
  isSiteUnderMaintenance = environment.settings.isSiteUnderMaintenance ?? false;
  authData: Partial<AuthData> = {};

  constructor(
    private push: PushNotifyService,
    public shared: SharedService,
    public lang: LangService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private authDataStore: Store<{ authData: AuthData }>,
  ) {}

  ngOnInit() {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => {
        this.authData = res;
      });

    document.body.setAttribute('id', this.app);

    if (this.isPushNotificationSupport && this.shared.isLogin()) {
      this.push.subscribeToNotifications();
      this.push.showMessages();
    }

    if (this.lang.isRTL) {
      document.body.setAttribute('dir', 'rtl');
      const htmlElement = document.getElementsByTagName('html')[0];
      htmlElement.setAttribute('dir', 'rtl');
    }

    if (this.freshchat.isShow) {
      this.initFreshChat();
      if (!!(window as any).fcWidget) {
        if (this.freshchat.pages === 'all') {
          (window as any).fcWidget.show();
        } else if (this.freshchat.pages === 'support') {
          (window as any).fcWidget.show();
        } else {
          (window as any).fcWidget.hide();
        }
      }
    }

    // closing freshchat on clicked outside of freshchat
    this.renderer.listen('document', 'click', (event: MouseEvent) => {
      const launcher =
        this.elementRef.nativeElement.querySelector('.hotline-launcher');
      if (launcher == null) {
        (window as any).fcWidget.close();
      }
    });
  }

  initFreshChat() {
    const authData = this.authData;

    try {
      (window as any).fcWidgetMessengerConfig = {
        open: false,
        tags: environment.settings.freshchat.tags,
        config: {
          headerProperty: {
            appName: environment.settings.appName,
            // direction: 'rtl',
            hideChatButton: environment.settings.freshchat.chat,
          },
          content: {
            welcomeMessage: 'Chat with Us',
            welcomeSubMessage: 'Solution Articles',
          },
          disableEvents: true,
          eagerLoad: true,
        },
      };

      (window as any).fcSettings = {
        onInit: function () {
          (window as any).fcWidget.setExternalId(
            authData?.tokenAuth?.user_details?.email,
          );
          (window as any).fcWidget.user.setFirstName(
            authData?.tokenAuth?.user_details?.first_name,
          );
          (window as any).fcWidget.user.setLastName(
            authData?.tokenAuth?.user_details?.last_name,
          );
          (window as any).fcWidget.user.setEmail(
            authData?.tokenAuth?.user_details?.email,
          );
          (window as any).fcWidget.user.setProperties({
            organization: authData?.tokenAuth?.organization_details?.slug,
          });
        },
      };

      var chatElement = document.createElement('script');
      chatElement.setAttribute('src', environment.settings.freshchat.script);
      chatElement.setAttribute('chat', 'true');
      document.body.appendChild(chatElement);
    } catch (error) {}
  }
}
