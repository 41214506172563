import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemporarilyUnavailableComponent } from './temporarily-unavailable.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TemporarilyUnavailableComponent],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [TemporarilyUnavailableComponent]
})
export class TemporarilyUnavailableModule { }
