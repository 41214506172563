import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthData } from 'src/app/utility/model/token-auth';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Reward } from 'src/app/utility/model/reward';
import { AddressChoice } from 'src/app/utility/model/address-choice';
import { ShippingAddressMode } from 'src/app/utility/enum';
import { RedemptionResponse } from 'src/app/utility/model/redemption-response';
import {
  isPhysicalAmazonProduct,
  renderImg1,
} from 'src/app/utility/common-logic';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api-services/api.service';
import { clearLocalStorage } from 'src/app/utility/local-storage';
import { TranslateService } from '@ngx-translate/core';

interface DialogData {
  reward: Reward;
  category?: string;
  qty: number;
  addressChoice: AddressChoice;
  remainingPoints: number;
  pointsDeducted: number;
  redemptionResponse: RedemptionResponse;
  totalAmountPayable: number;
  isOnlinePayment?: boolean;
  isOnlinePaymentCart?: boolean;
  cartDetails?: any;
  redeemableDetails?: any;
}

@Component({
  selector: 'app-order-placed',
  templateUrl: './order-placed.component.html',
  styleUrls: ['./order-placed.component.scss'],
})
export class OrderPlacedComponent implements OnInit {
  authData: Partial<AuthData> = {};
  cartDetails: any;
  redeemableDetails: any;
  showBackToWebsiteBtn = environment.settings.showBackToWebsiteBtn;
  showPoints = environment.settings.showPoints;
  websiteLink = environment.url.SSOUrl;

  constructor(
    private authDataStore: Store<{ authData: AuthData }>,
    private dialogRef: MatDialogRef<OrderPlacedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
    private api: ApiService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => (this.authData = res));
    if (this.data.cartDetails) {
      this.cartDetails = this.data.cartDetails;
    }
    if (this.data?.isOnlinePaymentCart) {
      this.redeemableDetails = this.data?.redeemableDetails;
    }
  }

  icon(reward: Reward | undefined) {
    return renderImg1(reward);
  }

  get hasAddress() {
    return this.data.category === 'Product';
  }

  get addressDisplay1() {
    if (this.data.addressChoice?.mode === ShippingAddressMode.physicalAddress) {
      return (
        this.data.addressChoice.pa?.address_line_1 +
        ' ' +
        this.data.addressChoice.pa?.address_line_2
      );
    } else if (this.data.addressChoice?.mode === ShippingAddressMode.sfStore) {
      return 'SF Store Self-Collection';
    } else if (this.data.addressChoice?.mode === ShippingAddressMode.sfLocker) {
      return 'SF Locker Self-Collection';
    }
    return '';
  }

  get addressDisplay2() {
    if (this.data.addressChoice?.mode === ShippingAddressMode.physicalAddress) {
      return this.data.addressChoice.pa?.contact_number;
    } else if (this.data.addressChoice?.mode === ShippingAddressMode.sfStore) {
      return this.data.addressChoice?.sfStore;
    } else if (this.data.addressChoice?.mode === ShippingAddressMode.sfLocker) {
      return this.data.addressChoice?.sfLocker;
    }
    return '';
  }

  async viewOrder() {
    this.dialogRef.close();
    if (
      this.data?.reward?.redemption_type === 'physical_product' ||
      (this.data?.qty === 1 && this.data.redemptionResponse)
    ) {
      await this.router.navigate([
        '/reward/redemption',
        this.data.redemptionResponse.pk,
      ]);
    } else {
      await this.router.navigate(['/rewards/my-wallet']);
    }
  }

  async signOut() {
    await this.api.logOut();
    clearLocalStorage();
    this.api.clearCacheStorage();

    if (this.websiteLink) {
      window.location.href = this.websiteLink;
    } else {
      this.router.navigate(['/login']);
    }
  }

  get remainingPointsAfterDeduction() {
    if (
      Number(this.data?.remainingPoints) > Number(this.data?.pointsDeducted)
    ) {
      return this.data?.remainingPoints - Number(this.data?.pointsDeducted);
    }
    return 0;
  }

  get remainingPointsAfterDeductionCart() {
    if (
      this.data?.cartDetails.user_points > Number(this.data?.pointsDeducted)
    ) {
      return (
        this.data?.cartDetails.user_points - Number(this.data?.pointsDeducted)
      );
    }
    return 0;
  }

  get isPhysicalAmazonProduct() {
    return isPhysicalAmazonProduct(this.data.reward);
  }
  get orderPlacedText() {
    return this.isPhysicalAmazonProduct || !!this.data?.cartDetails
      ? this.translate.instant('common.orderPlaced')
      : this.translate.instant('common.orderRedeemed');
  }
  get orderSuccessText() {
    return this.isPhysicalAmazonProduct || !!this.data?.cartDetails
      ? this.translate.instant('msg.orderSuc')
      : this.translate.instant('msg.orderSucRedemption');
  }

  get viewOrderButtonText() {
    return this.isPhysicalAmazonProduct || !!this.data?.cartDetails
      ? this.translate.instant('common.viewOrder')
      : this.translate.instant('common.viewRedemption');
  }
}
