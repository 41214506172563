import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ImgFile } from 'src/app/utility/model/images';
import { Router } from '@angular/router';
import { ImageService } from 'src/app/services/image.service';
import { AddReceiptImageComponent } from './add-receipt-image/add-receipt-image.component';
import { ProcessReceiptImageComponent } from './process-receipt-image/process-receipt-image.component';
import { EditReceiptImageComponent } from './edit-receipt-image/edit-receipt-image.component';
import { Store } from '@ngrx/store';
import { AuthData } from 'src/app/utility/model/token-auth';

interface DialogData {
  dialogMode: boolean;
  newReceiptFlow: number;
  uploadImageFromReceiptForm: boolean;
}

@Component({
  selector: 'app-upload-receipt',
  templateUrl: './upload-receipt.component.html',
  styleUrls: ['./upload-receipt.component.scss'],
})
export class UploadReceiptComponent {
  image: ImgFile | undefined;
  authData: Partial<AuthData> = {};

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private dialogRef: MatDialogRef<UploadReceiptComponent>,
    private imageService: ImageService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authDataStore: Store<{ authData: AuthData }>,
  ) {}

  ngOnInit() {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => {
        this.authData = res;
      });

    if (this.data?.uploadImageFromReceiptForm) {
      this.uploadImage();
    }
  }

  get route() {
    let route = '';
    if (
      this.authData?.tokenAuth?.organization_settings
        ?.enable_benefit_receipts_upload === 1 &&
      this.authData?.tokenAuth?.organization_settings
        ?.enable_receipts_upload === 0
    ) {
      route = '/add-receipt';
    } else if (
      this.authData?.tokenAuth?.organization_settings
        ?.enable_receipts_upload === 1
    ) {
      route = '/add-sales-receipt';
    }
    return route;
  }

  uploadImage() {
    const dialogRef = this.dialog.open(AddReceiptImageComponent, {
      maxWidth: '100vw',
      maxHeight: '90vh',
      data: {
        dialogMode: true,
        image: this.image,
      },
    });
    dialogRef.afterClosed().subscribe(async (res) => {
      if (!!res) {
        this.image = res[0];
        this.imageService.setImageData(this.image);
        if (this.data?.newReceiptFlow === 1) {
          this.processReceiptImage();
        } else {
          this.router.navigate([this.route]);
          this.dialogRef.close();
        }
      }
    });
  }

  processReceiptImage() {
    const processReceiptDialogRef = this.dialog.open(
      ProcessReceiptImageComponent,
      {
        maxWidth: '100vw',
        maxHeight: '90vh',
        data: {
          dialogMode: true,
          image: this.image,
        },
      },
    );
    processReceiptDialogRef.afterClosed().subscribe(async (res) => {
      if (res !== undefined) {
        if (res.image) {
          this.image = res.image;
          this.imageService.setImageData(this.image);
        }
        if (this.data.uploadImageFromReceiptForm) {
          this.dialogRef.close(res);
        } else {
          this.router.navigate([this.route], { state: { response: res } });
          this.dialogRef.close();
        }
      }
      if (res === undefined) {
        this.dialogRef.close();
      }
    });
  }

  editReceiptImage() {
    const editImageDialogRef = this.dialog.open(EditReceiptImageComponent, {
      maxWidth: '100vw',
      maxHeight: '90vh',
      data: {
        dialogMode: true,
        image: this.image,
        confirmImage: true,
      },
    });
    editImageDialogRef.afterClosed().subscribe(async (res) => {
      if (!!res) {
        this.image = res;
        this.imageService.setImageData(this.image);
        if (this.data?.newReceiptFlow === 1) {
          this.processReceiptImage();
        } else {
          this.router.navigate([this.route]);
          this.dialogRef.close();
        }
      }
    });
  }

  goToAddReceiptPage() {
    this.image = null;
    this.imageService.setImageData(this.image);
    this.router.navigate([this.route]);
  }
}
