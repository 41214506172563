export default {
  address: {
    contactNumberLength: 0,
    contactNumberMinLength: 0,
    emailRequired: false,
    hidePin: false,
    hideCountry: false,
    fixCountry: '',
    pinCodeRequired: false,
    pinCodeLength: 0,
    pinCodeDigitOnly: false,
  },
  allowInsufficientPoint: false,
  app: 'bous',
  appName: 'BOUS',
  drawerBtnLabel: 'common.filter._1',
  feeds: {
    label: 'common.home',
    path: 'home',
  },
  filters: {
    country: false,
  },
  freshchat: {
    isShow: true,
    pages: 'support',
    script: '//fw-cdn.com/2220685/2889759.js',
    chat: false,
    tags: [],
  },
  footer: {
    privacyLink: 'https://abundantly.com/privacy-policy/',
    tncLink: 'https://abundantly.com/privacy-policy/',
    userGuide:
      'https://thankabundantly.com/media/attachment/Application%20User%20Guide%20Version%201.1.pdf',
  },
  header: {
    dynamicBg: true,
    dynamicLogo: true,
    firstLogo: true,
    secondLogo: '',
    pointDetail: true,
    notification: true,
    showDepartmentInProfile: false,
  },
  hideRewardsTnC: true,
  downloadNominationCertificate: true,
  infiniteStockEVoucher: true,
  languageAvailable: ['en', 'es'],
  languageLabel: {
    en: 'English',
    es: 'Español',
  },
  leftNavigate: ['appreciate', 'feeds', 'rewards', 'settings'],
  modules: {
    home: false,
    appreciate: true,
    feeds: true,
    rewards: true,
    myWallet: false,
    settings: true,
    pointsHistory: false,
    cart: false,
  },
  myFeeds: {
    approvals: false,
    myRecommendations: false,
  },
  panel: {
    link: ['/profile'],
    support: true,
  },
  pointDetailTitle: 'common.pointDetails',
  randomizeRecommendation: true,
  rating: false,
  recentRecognition: true,
  recognitionRecommendation: true,
  registerNewAccount: false,
  removeRewardRedemption: true,
  rewards: {
    detailQty: 2,
    seeAll: {
      subcategory: 'drawer',
    },
    filters: {
      points: false,
      pointsCollapsible: false,
      rating: false,
    },
  },
  loginTermsCondition: true,
  settings: {
    hideMyAppreciationMessagesToggle: true,
    makeMyBirthdayPublicToggle: true,
    makeMyAnniversaryPublicToggle: true,
    makeMyProfilePublicToggle: false,
    passwordChangeShow: true,
    passwordChangeStyle: 'inline',
  },
  showFooterContactUsDialog: true,
  showPointCategories: true,
  transactionHistoryRewardNameTranslatable: true,
  thankMessageLeftBox: true,
  whoToThankTab: true,
};
